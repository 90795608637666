<template>
  <div class="containers">
    <myheader></myheader>
    <div class="picture">
      <!-- <img src="../assets/picture.png" /> -->
      <img src="@/assets/picture.png" />
    </div>
    <div class="buttonGroup">
      <div>
        <div @click="switchToResoucesSection(section)" class="button" v-for="(section, index) in sectionList" :key="'cate-' + index">
          {{ section.name }}
        </div>
      </div>
    </div>
    <div v-if="resources" class="resourcePackage">
      <div class="packages">
        <div v-for="(item, index) in resoucesList" :key="index" class="package wow animate__fadeInUp">
          <div class="title">
            <div class="icon">
              <img src="@/assets/book.png" />
            </div>
            <div class="name">
              <span> 《{{ item.title }}》 </span>
            </div>
          </div>
          <div class="button">
            <div class="download" @click="downloadResources(item)">下载资源</div>
          </div>
          <div class="pwd">
            <span v-if="item.pwd != null && item.pwd.length > 1">网盘密码:{{ item.pwd }}</span>
          </div>
        </div>
      </div>
      <div class="end">
        <div class="endBox">
          <div class="imgs"><img src="@/assets/line.png" /></div>
          <div class="text"><span>如需要更多资源，请联系  &nbsp;jsydy@1daoyun.com</span></div>
          <div class="imgs"><img src="@/assets/line.png" /></div>
        </div>
      </div>
    </div>
    <div v-if="information">行业资讯</div>
    <mybottom></mybottom>
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {
  components: { myheader, mybottom },
  data() {
    return {
      resources: true,
      information: false,
      sectionList: [],
      resoucesList: [],
      selectedSectionId: 0,
    };
  },
  async created() {
    await this.loadSectionList();
    this.selectedSectionId = this.sectionList[0].id;
    await this.loadResoucesList();
  },
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  methods: {
    async loadSectionList() {
      let reqData = {};
      let loadResoucesSectionUrl = `/home/loadResoucesSection`;
      let result = await this.axios.get(loadResoucesSectionUrl, reqData);
      this.sectionList = result.data.data;
    },
    async loadResoucesList() {
      let reqData = {};
      let reqParam = { page: '1', pageSize: '20', sectionId: this.selectedSectionId };
      let urlParam = `?sectionId=${reqParam.sectionId}&`;
      let loadResourcesListUrl = `/home/listResources/${reqParam.page}/${reqParam.pageSize}${urlParam}`;
      let result = await this.axios.get(loadResourcesListUrl, reqData);
      console.log(result);
      this.resoucesList = result.data.data;
    },
    switchToResoucesSection(section) {
      this.selectedSectionId = section.id;
      this.loadResoucesList();
    },
    downloadResources(item) {
      if (item.type == '网盘') {
        window.open(item.link);
      } else {
        window.open(this.cdn + item.link);
      }
    },
  },
};
</script>

<style>
.full-container {
  min-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.containers {
  min-width: 1200px;
  height: auto;

  /* margin: 0px;
  padding: 0px; */
  margin: 0 auto;
  overflow: hidden;
}
.picture {
  width: 100%;
  /* height: 420px; */
  margin: 0 auto;
}
.picture img {
  width: 100%;
  height: 100%;
}
.buttonGroup {
  width: 100%;
  height: 60px;
  border-bottom: #eeeeee 1px solid;
  margin: 0 auto;
  overflow: hidden;
}
.buttonGroup > div {
  display: flex;
  width: 360px;
  height: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  /* background-color: chartreuse; */
}
.button {
  flex: 1;
  line-height: 60px;
  font-size: 18px;
  color: #666666;
  text-align: center;
  cursor: pointer;
}
/* .button:active {
  background-color: #f2f2f2;
  color: #2e71fe;
} */
.resourcePackage {
  width: 1200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  overflow: hidden;
}
.resourcePackage .packages {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
}
.package {
  width: 360px;
  height: 204px;
  border: #eeeeee 2px solid;
  border-radius: 5px;
  margin-bottom: 40px;
  overflow: hidden;
}
.package .title {
  width: 90%;
  height: 45px;
  margin-top: 40px;
  display: flex;
  font-size: 16px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.icon img {
  height: 100%;
}
.package .button {
  width: 100%;
  height: 40px;
  margin-top: 30px;
}
.download {
  width: 140px;
  height: 40px;
  background-color: #2e71fe;
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #ffffff;
}
.package .pwd {
  width: 100%;
  height: 15px;
  margin-top: 10px;
  font-size: 10px;
  color: #666666;
  line-height: 15px;
  text-align: center;
}
.end {
  width: 100%;
  height: 20px;
  /* margin-top: 50px; */
  margin-bottom: 60px;
}
.endBox {
  width: 70%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}
.endBox .imgs {
  width: 30%;
  height: 100%;
}
.endBox .imgs img {
  width: 100%;
  width: 100%;
}
.endBox .text {
  width: 100%;
  height: 100%;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
}

</style>
